.App {
  padding: 50px;
  display: flex;
  justify-content: space-evenly;
}

/* pidl-react-page-error isn't in used the pidl whole page error message. Hide it show to event information and PI information side by side */
.pidl-react-page-error
{
  display: none;
}

@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
}

#pidlddc-div-expiryCvvGroup {
  justify-content: space-between;
}
  
div[id^="pidlddc-div-creditCard"][id$="CheckoutAddGroup"] {
  max-width: 480px;
  flex: 2;
}

#pidlddc-div-productInfoGroup {
  max-width: 360px;
  flex: 1
}

div[id^="pidlddc-div-creditCard"][id$="CheckoutAddGroup"] .ms-StackItem {
  padding: 4px 0 4px 0;
}

/* heading */
#pidlddc-text-add_credit_debit_heading, #pidlddc-text-paymentInformation, #pidlddc-text-contactInformationHeading,#pidlddc-text-productDetailHeading, #pidlddc-text-productName{
  font-size: 18px;
  padding: 8px 0 8px 0;
}

#pidlddc-text-productName {
  font-size: 24px;
}

#pidlddc-div-termsPrivacyCheckoutGroup .ms-StackItem{
  padding: 7px 2px 7px 2px;
}

#pidlddc-text-accept_card_message, #pidlddc-text-mandatory_fields_message{
  padding: 5px 0px 5px 0px;
}

#pidlddc-div-cancelPaySubmitGroup .ms-StackItem{
  padding: 0px 8px 0px 0;
}

#pidlddc-div-expiryMonthYearGroup .ms-StackItem{
  padding: 0px 5px 0px 0px
}

div[id^="pidlddc-div-creditCard"][id$="CheckoutAddGroup"] .ms-Label{
  font-size: 12px;
  font-weight: 400;
}

.ms-Label::after {
  content: ""
}

.ms-Label::after {
  content: ""
}

.ms-Dropdown::before{
  content: ""
}

#pidlddc-text-paymentErrorSubText {
    padding-bottom: 40px;
}

#pidlddc-image-paymentErrorImage {
    padding-right: 10px;
}

#pidlddc-text-paymentErrorText {
    padding-bottom: 30px;
}

div#pidlddc-div-multiplePaymentMethodLogosRowOneGroup img[id^=pidlddc-image] {
  height: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  padding: 0px 3px 0px 0px;
}
div#pidlddc-div-paymentMethodOption_0 {
  margin-left: auto;
}
.ms-ChoiceField-wrapper {
  width: 100%;
}
.ms-ChoiceField-wrapper input ~ div {
  height: 70px;
  padding: 0px 0px 0px 5px;
}
.ms-ChoiceField-wrapper input:checked ~ div {
  background: #EBEBEB;
}

#pidlddc-image-paypalLogo {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    margin-top: 8px;
}

#pidlddc-div-termsPrivacyCheckoutGroup {
    margin-top: 24px;
    margin-bottom: 24px;
}

#pidlddc-button-backButton {
    margin-right: 8px
}

#pidlddc-text-paymentInformationHeading {
    margin-top: 16px;
}

#ChoiceGroupLabel12 {
    margin-top: 24px;
    margin-bottom: 24px;
}

#pidlddc-text-paymentErrorCV {
    margin-bottom: 40px;
}

#pidlddc-text-add_credit_debit_heading {
  font-size: 14px;
}

#pidlddc-text-paymentInformationHeading {
  font-size: 14px;
}

#iframe_ThirdPartyPaymentsCheckoutChallengeIFrame
{
  height: 750px;
  width: 100%;
  border: none;
}

#pidlddc-hyperlink-termsCheckout, #pidlddc-hyperlink-privacyCheckoutStatement {
  text-decoration: underline;
}
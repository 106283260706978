.App {
    padding: 20px;
    display: flex;
    justify-content: space-evenly;
} 

#pidlddc-text-add_credit_debit_heading{ 
    font-size: 20px;
}

#pidlddc-div-accountHolderName{ 
    padding-bottom: 20px;
}

#pidlddc-div-accountToken{ 
    padding-bottom: 20px;
}
  
#pidlddc-div-expiryAddGroup {
    padding-bottom: 10px;
}

#pidlddc-div-expiryMonth { 
    padding-right: 5px;
}

#pidlddc-div-cvvToken { 
    width: 86px;
    margin-bottom: 20px;
}

#pidlddc-div-address_line1{
    margin-bottom: 20px;
    margin-right: 5px
}

#pidlddc-div-city{
    margin-bottom: 20px;
    margin-right: 5px
}

#pidlddc-div-postal_code{
    margin-bottom: 20px;
    margin-right: 5px
}

#pidlddc-div-microsoftPrivacyTextGroup { 
    margin-bottom: 20px;
    margin-right: 5px
}

#pidlddc-button-cancelButton {
    margin-right: 5px;
}

#pidlddc-text-welcomeUserText { 
    font-size: 20px;
}

#pidlddc-text-welcomeUserEmail { 
    font-size: 15px;
    margin-top: 5px;
}

#pidlddc-div-consentCheckboxProperty { 
    font-size: 20px;
    width: "49%";
}